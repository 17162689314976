body.error .error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 84px 0 110px;
  position: relative;
  box-sizing: border-box;
}
body.error .error-message .message {
  text-align: center;
  margin-top: 42px;
  max-width: 420px;
}
body.error .error-message .message h3 {
  color: #000000;
  font-weight: 700;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
body.error .error-message .message p {
  font-weight: 600;
  margin-top: 20px;
  color: #000000;
  text-align: center;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
body.error .error-message .message .btn {
  margin-top: 30px;
  min-width: 210px;
  justify-content: center;
}
body.error .error-message .error-image {
  position: relative;
  max-width: 100%;
  width: 448px;
  height: 268px;
}
body.error .error-message .error-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
body.error .error-message.unexpected .error-image {
  width: 474px;
  height: 283px;
}
body.error .error-message.not-found {
  margin: 40px 0 50px;
}
body.error .error-message.not-found .message {
  margin: 0;
}
body.error .error-message.not-found .message h3 {
  font-weight: bold;
  font-size: calc(24px + var(--big-fs));
  line-height: 30px;
}
body.error .error-message.not-found .message p {
  font-weight: normal;
  margin-top: 40px;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
body.error .error-message.not-found .main-listing-container {
  margin-top: 20px;
}
@media (max-width: 768px) {
  body.error .error-message {
    margin: 80px 0 100px;
    padding-bottom: 0;
  }
  body.error .error-message .error-image {
    max-width: 100%;
    min-height: 200px;
    height: unset;
  }
  body.error .error-message .message {
    width: 100%;
  }
  body.error .error-message .message .btn {
    width: 100%;
  }
}
body.error .popular-cities-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: auto;
  margin: 0 24px;
  grid-gap: 20px 30px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
@media (max-width: 768px) {
  body.error .popular-cities-container {
    grid-gap: 20px 10px;
    margin: 0;
  }
}
body.error #latest-status-messages-carousel {
  display: none;
}
body.simple-error {
  max-width: 960px;
  margin: 0 auto;
  background-color: #f0f3f8;
}
body.simple-error header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: auto;
  height: 80px;
  min-height: 90px;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  background-color: #000000;
}
body.simple-error header a {
  max-width: 100%;
  overflow: hidden;
}
body.simple-error header a img {
  height: 55px;
  width: 300px;
  object-fit: contain;
  max-width: 100%;
}
body.simple-error main {
  display: block;
  height: auto;
  min-height: calc(100vh - 90px);
  background-color: #ffffff;
}
body.simple-error main .error-message-simple {
  position: relative;
  padding: 110px 16px;
}
body.simple-error main .error-message-simple img {
  display: block;
  max-width: 534px;
  width: 100%;
  object-fit: contain;
  object-position: top;
  margin: 0 auto;
}
body.simple-error main .error-message-simple h2 {
  text-align: center;
  color: #000000;
  margin-top: 20px;
  text-transform: capitalize;
  font-size: calc(22px + var(--big-fs));
  line-height: 28px;
}
@media (max-width: 768px) {
  body.simple-error main .error-message-simple h2 {
    margin-top: 50px;
  }
}
